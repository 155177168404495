import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import localeDa from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';
import { LurpakItemComponent } from './lurpak-item/lurpak-item.component';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeDa, 'da-DK')

@NgModule({
  declarations: [
    AppComponent,
    LurpakItemComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserModule
  ],
  providers: [ {provide: LOCALE_ID, useValue: 'da-DK'} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
