<header class="header"></header>

<ng-container *ngIf="data$ | async as data">
  <div class="content">
  <section>
    <h1>Aktuelle tilbud</h1>
    <app-lurpak-item
      *ngFor="let item of data.current"
      [item]="item"
    ></app-lurpak-item>

    <h1>Kommende tilbud</h1>
    <app-lurpak-item
      *ngFor="let item of data.upcomming"
      [item]="item"
    ></app-lurpak-item>
  </section>
</div>
  <footer>
    Sidst opdateret: {{ data.lastUpdated.toDate() | date: "medium" }}
  </footer>
</ng-container>
