import { Component, OnInit, Input } from '@angular/core';
import { Offer } from '../offer';

@Component({
  selector: 'app-lurpak-item',
  templateUrl: './lurpak-item.component.html',
  styleUrls: ['./lurpak-item.component.scss']
})
export class LurpakItemComponent implements OnInit {

  @Input() item: Offer;

  constructor() { }

  ngOnInit(): void {
  }

}
