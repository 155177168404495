import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Offer } from './offer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'lurpak';
  data$: Observable<any>;
  current$: Observable<any>;
  upcomming$: Observable<any>;

  constructor(private af: AngularFirestore) {

  }
  ngOnInit(): void {

    this.data$ = this.af.doc('discounts/lurpak').valueChanges().pipe(map((data: any) => {
      const newdata = {
        upcomming: [],
        current: [],
        past: [],
        lastUpdated : data.lastUpdated
      };
      data.data.forEach(element => {
        let pricePrKg = null;

        const { quantity, pricing } = element;

        // figure out if we are able to calculate pricePrKg
        if (quantity.pieces.from === quantity.pieces.to && quantity.unit.symbol === 'g') {
          pricePrKg = (1000 / (quantity.size.from * quantity.pieces.from)) * pricing.price;
        }
        const item: Offer = {
          rawData: element,
          retailer: element.branding.name,
          pricePrUnit: element.pricing.price,
          unitSize: element.quantity.size.from,
          pricePrKg,
          image: element.images.view,
          description: element.description,
          from: new Date(element.run_from),
          till: new Date(element.run_till)
        };
        if (moment().isAfter(item.from)) {
          newdata.past.push(item);
        }
        if (moment().isBetween(item.from, item.till)) {
          newdata.current.push(item);
        }
        if (moment().isBefore(item.from)) {
          newdata.upcomming.push(item);
        }
      })
      return newdata;
    }));
  }
}
