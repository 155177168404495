<div class="details">
  <!-- <pre>{{ item.rawData | json }}</pre> -->
  <div class="retailer">{{ item.retailer }}</div>
  <div class="dates">
    fra {{ item.from | date: "mediumDate" }} til
    {{ item.till | date: "mediumDate" }}
  </div>
  <div class="description">
    {{ item.description }}
  </div>
</div>

<div class="price-details">
  <div class="price">{{ item.pricePrUnit | currency: "DKK":"" }}</div>
  <div class="price-pr-kg" *ngIf="item.pricePrKg">
    kilopris {{ item.pricePrKg | currency: "DKK":"" }}
  </div>
</div>

<!-- <div *ngFor="let item of data.upcomming">
  <div class="row">
    <div class="col">{{item.branding.name }}</div>

    <div class="col">{{item.description }}</div>

    <div class="col">{{item.pricing.price | currency:'DKK': '' }}</div>

    <div class="col">{{item.run_from }}</div>
    <div class="col">{{item.run_till }}</div>
  </div>


</div> -->
